import cn from 'classnames'
import styles from './styles.module.scss'
import { FC, SetStateAction, Dispatch, useState, useEffect } from 'react'
import Button from '@atoms/Button'
import { usePostUserCodeMutation, useGetDataUserQuery } from '@services/user'

interface Props {
  phone: string
  nextSms: boolean
  setNextSms: Dispatch<SetStateAction<boolean>>
  setProfileUser: Dispatch<SetStateAction<string[]>>
  setStepTwo: Dispatch<SetStateAction<boolean>>
  setCheckoutPay: Dispatch<SetStateAction<number>>
  checkoutPay: number
}

const InputCode: FC<Props> = ({
  phone,
  nextSms,
  setNextSms,
  setProfileUser,
  setStepTwo,
  setCheckoutPay,
  checkoutPay,
}) => {
  const [code, setCode] = useState(['', '', '', ''])
  const [token, setToken] = useState('')
  const [falseCode, setFalseCode] = useState(false)
  const [timeLeft, setTimeLeft] = useState(300)
  const [postUserCode, { data: link }] = usePostUserCodeMutation()
  const {
    data: profile,
    error,
    // isLoading,
  } = useGetDataUserQuery(token, { skip: !token })

  useEffect(() => {
    const currentAuth: string | null = localStorage.getItem('tokenUser')
    if (currentAuth && currentAuth.trim() !== '') {
      // console.log('check token', currentAuth)
      setToken(JSON.parse(currentAuth))
    }
  }, [])

  const handleChange = (index: number, value: any) => {
    if (/^\d?$/.test(value)) {
      const newCode = [...code]
      newCode[index] = value
      setCode(newCode)
    }
  }
  // const profiles = {
  //   avatar: null,
  //   nickname: 'Testrest',
  //   phone: '79505560743',
  //   gender: 1,
  //   subscribe_active: true,
  //   subscribe_end_date: '2025-01-02 00:00:00',
  // }

  useEffect(() => {
    if (profile) {
      const data: any = profile
      setProfileUser(data)
      // console.log('data from profile', data)
    }
    if (error) {
      // console.log('data profile not get', error)
      localStorage.removeItem('tokenUser')
      setCheckoutPay(1)
      setStepTwo(false)
    }
  }, [profile, setProfileUser, error, setStepTwo, setCheckoutPay])

  const checkCode = async () => {
    const data = {
      phone: phone.replace(/[+\s()]/g, ''),
      code: code.join(''),
    }
    const response: any = await postUserCode(data)
    if (response.error) {
      console.error('Error during POST request:')
      setFalseCode(true)
    }
  }

  useEffect(() => {
    if (!link) {
      // console.log('!link.success2', link)
      return
    }
    if (link.success === true) {
      localStorage.setItem('tokenUser', JSON.stringify(link.token))
      setToken(link.token)
      setFalseCode(false)
    }
    // else {
    //   console.log('Error: code is incorrect', link)
    //   setFalseCode(true)
    // }
  }, [link])

  useEffect(() => {
    let timer: any = null
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1)
      }, 1000)
    } else {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [timeLeft, nextSms])

  const submit = () => {
    setNextSms(true)
    setTimeLeft(300)
    setCode(['', '', '', ''])
    setFalseCode(false)
  }

  return (
    <>
      {checkoutPay > 0 && (
        <>
          <div>
            {code.map((digit, index) => (
              <input
                key={index}
                type="number"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                className={cn(styles.numberCode, {
                  [styles.false]: falseCode,
                })}
              />
            ))}
          </div>
          <div className={styles.timeNewCode}>
            {timeLeft > 0 ? (
              `Запросить новый код можно через ${Math.floor(timeLeft / 60)
                .toString()
                .padStart(2, '0')}:${(timeLeft % 60)
                .toString()
                .padStart(2, '0')} сек`
            ) : (
              <button onClick={submit} className={styles.newCodeOrder}>
                Запросить новый код
              </button>
            )}
          </div>
          <Button className={styles.checkCode} onClick={checkCode}>
            Подтвердить
          </Button>
        </>
      )}
    </>
  )
}

export default InputCode
